import React, {
  useCallback, useState, useMemo,
} from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { DateFilter } from 'ag-grid-community';
import Header from '../tools/components/Header';
import Vectorback from '../../resources/images/Vectorback.png';
import ModalConfirm from '../modal/ModalConfirm';
import ModalSuccess from '../modal/ModalSuccess';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  convertWidth, valueTranslate,
} from '../common/utils';
import fetchApi from '../FetchApi';
import {
  BillingMethod, Member, PaymentMethod, PaymentStatus, RatePlan,
} from '../common/DefineClass';
import NewTable from '../components/NewTable';
import { helperActions } from '../../store';
import UseFilter from '../components/UseFilter';
import FilterComponent from '../components/FilterComponent';
import FloatingFilterComponent from '../components/FloatingFilterComponent';
import DatePickerEditor from '../components/DatePickerEditor';
import DetailsEditDateEditor from './components/DetailsEditDateEditor';
import StatiticUsers from './components/StatiticUsers';
import { formatLocalToUTC, formatUTCToLocal } from '../../common/util/convertTimeZone';

const listColumPercent = {
  category: 8,
  status: 10,
  name: 12,
  userId: 8,
  disabled: 7,
  email: 20,
  representId: 9,
  paymentId: 9,
  billingMethod: 12,
  ratePlan: 15,
  paymentMethod: 12,
  paymentSpan: 15,
  price: 8,
  lastBillingDate: 8,
  nextBillingDate: 8,
  startDate: 10,
  phone: 10,
  withdrawDate: 10,
  updateDate: 8,
  type: 7,
};

const EditPage = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [listDataChange, setListDataChange] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const userModel = new Member();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateUser = async () => {
    dispatch(helperActions.setLoading(true));

    const response = await fetchApi('/api/admin/payment_status/settings_v2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(listDataChange),
    });
    if (response.ok) {
      setOpenSuccess(true);
    }
    dispatch(helperActions.setLoading(false));
  };

  const editColumnDefs = [
    {
      headerName: t('categoryTableHeader1'),
      width: convertWidth(10),
      field: 'category1',
      valueFormatter: (params) => valueTranslate(t, params.value, 'category1'),
      filter: UseFilter,
      filterParams: {
        title: 'Category Filters',
        values: [{ value: 1, category: 'category1' }, { value: 0, category: 'category1' }],
      },
      floatingFilter: false,
    },
    {
      headerName: t('categoryTableHeader2'),
      width: convertWidth(8),
      field: 'role',
      valueFormatter: (params) => valueTranslate(t, params.value, 'role'),
      filter: UseFilter,
      filterParams: {
        title: 'Category Filters',
        values: [{ value: 2, category: 'role' }, { value: 1, category: 'role' }],
      },
      floatingFilter: false,
    },
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: userModel.field2,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
      filter: UseFilter,
      filterParams: {
        title: 'Category Filters',
        values: [{ value: 1, category: 'account' }, { value: 0, category: 'account' }],
      },
      floatingFilter: false,
    },
    {
      headerName: t('nameTableHeader'),
      field: userModel.field4,
      width: convertWidth(listColumPercent.name),
      editable: true,
      filter: FilterComponent,
      floatingFilter: false,
    },
    {
      headerName: t('userId'),
      field: userModel.field9,
      width: convertWidth(listColumPercent.representId),
      filterParams: {
        type: 'NUMBER',
      },
      floatingFilterComponent: FloatingFilterComponent,
      floatingFilter: false,
    },
    {
      headerName: t('userStatusTableHeader'),
      field: userModel.field6,
      width: convertWidth(listColumPercent.disabled),
      cellRenderer: (params) => valueTranslate(t, params.value, 'account'),
      filter: UseFilter,
      filterParams: {
        title: 'Status Filters',
        values: [
          { value: 0, category: 'statusUsage' },
          { value: 1, category: 'statusUsage' },
        ],
      },
      floatingFilterComponent: null,
      floatingFilter: false,
    },
    {
      headerName: t('userEmailTableHeader'),
      field: userModel.field7,
      width: convertWidth(listColumPercent.email),
      floatingFilterComponent: FloatingFilterComponent,
      floatingFilter: false,
    },
    {
      headerName: t('representIdTableHeader'),
      field: userModel.field9,
      width: convertWidth(listColumPercent.representId),
      floatingFilterComponent: FloatingFilterComponent,
      filterParams: {
        type: 'NUMBER',
      },
      floatingFilter: false,
    },
    {
      headerName: t('typeApp'),
      field: 'typeApp',
      width: convertWidth(8),
      valueFormatter: (params) => valueTranslate(t, params.value, 'typeApp'),
      filter: UseFilter,
      filterParams: {
        title: 'Status Filters',
        values: [
          { value: 0, category: 'typeApp' },
          { value: 1, category: 'typeApp' },
          { value: 2, category: 'typeApp' },
        ],
      },
      floatingFilter: false,
    },
    {
      headerName: t('startDateTableHeader'),
      field: userModel.field27,
      width: convertWidth(listColumPercent.lastBillingDate),
      floatingFilterComponent: DatePicker,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          return formatUTCToLocal(inputDate);
        }
        return null;
      },
      filter: DateFilter,
      floatingFilter: false,
    },
    {
      headerName: t('endDateTableHeader'),
      field: userModel.field28,
      width: convertWidth(8),
      cellRenderer: (params) => {
        if (!params.value) return t('unLimited');
        const formattedDate = formatUTCToLocal(params.value);
        const lastDateOfCalendar = formatUTCToLocal('9999/12/31');
        return formattedDate === lastDateOfCalendar ? t('unLimited') : formattedDate;
      },
      filter: DateFilter,
      floatingFilter: false,
    },
    {
      headerName: t('noteTableHeader'),
      field: userModel.field31,
      width: convertWidth(10),
      filter: FilterComponent,
      floatingFilter: false,
      editable: true,
    },
    {
      headerName: t('updateDateTableHeader'),
      field: userModel.field22,
      width: convertWidth(listColumPercent.updateDate),
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          return formatUTCToLocal(inputDate);
        }
        return null;
      },
      filter: DateFilter,
      floatingFilter: false,
    },
  ];

  const onCellValueChanged = useCallback((e) => {
    const dataOnRow = e.data;
    const newObj = {
      id: dataOnRow?.id,
      name: dataOnRow?.name,
      email: dataOnRow?.email,
      payment_status_code: dataOnRow?.paymentstatuscode,
      billingMethod: dataOnRow?.billing_method,
      paymentMethod: dataOnRow?.payment_method,
      lastBillingDate: dataOnRow.lastBillingDate ? formatLocalToUTC(dataOnRow.lastBillingDate) : null,
      nextBillingDate: dataOnRow.nextBillingDate ? formatLocalToUTC(dataOnRow.nextBillingDate, true) : null,
      typeApp: dataOnRow?.typeApp,
      note: dataOnRow?.note,
      category: dataOnRow?.category,
      category1: dataOnRow?.category1,
      rateplan: dataOnRow?.rateplan,
      groupId: dataOnRow?.groupId,
      managerId: dataOnRow?.managedUserId,
    };
    setListDataChange((prevState) => {
      const index = prevState.findIndex((item) => item.id === newObj.id);
      if (index !== -1) {
        return prevState.map((item, i) => (i === index ? { ...item, ...newObj } : item));
      }
      return [...prevState, newObj];
    });
  });

  const dataTable = {
    showPagination: true,
    newCustomeColumn: editColumnDefs,
    saveData: true,
    refreshData,
    setRefreshData,
    onCellValueChanged,
    singleClickEdit: true,
    editStatus: true,
    floatingFilter: true,
  };

  return (
    <>
      <div className="list-account-export">
        <Header listPath="account" noDescription noBack topic="Export file CSV account">
          <div className="container-export">
            <StatiticUsers setRefreshData={setRefreshData} />
            <p className="title-header">{t('titleEditListAccount')}</p>
            <Button
              onClick={() => setOpenModal(true)}
              className="button-export"
              disabled={!(listDataChange.length > 0)}
            >
              <div>{t('sharedEdit')}</div>
            </Button>
            <Button className="button-back" onClick={() => navigate('/admin/home/list')}>
              <img src={Vectorback} alt="icon back" />
              {t('back')}
            </Button>
          </div>
        </Header>
      </div>
      <NewTable {...dataTable} />
      <ModalConfirm
        openModal={openModal}
        handleClose={handleCloseModal}
        action={handleUpdateUser}
        propsModal={{
          confirm: t('confirmEditAccount'),
          subTitle: t('confirmEditAccount2'),
          titleCancel: t('sharedCancel'),
          titleContinue: t('sharedPerform'),
        }}
      />
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={() => {
          setOpenSuccess(false);
          navigate('/admin/home/list');
        }}
        title={t('editingCompleted')}
      />
    </>
  );
};

export default EditPage;
