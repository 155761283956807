import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, Box, Grid, Checkbox, FormControlLabel,
  Radio, Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Header from '../tools/components/Header';
import ConfirmationPopup from '../tools/components/ComfirmationPopup';
import fetchApi from '../FetchApi';
import { helperActions } from '../../store';
import { encrypted } from '../../payment/components/utils';
import Vectorback from '../../resources/images/Vectorback.png';
import '../styles/pages/SettingAdminAccount.css';
import ModalSuccess from '../modal/ModalSuccess';

const useStyles = makeStyles({
  item: {
    '& .MuiGrid-item': {
      paddingTop: '0px',
      paddingLeft: '0px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
  },
  inforGrid: {
    border: '1px solid',
    backgroundColor: '#c5e0b4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxGrid: {
    border: '1px solid',
    backgroundColor: '#e2f0d9',
    padding: '5px',
  },
  disabledTextField: {
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: '#bfbfbf',
    },
    '& .MuiInputBase-input.Mui-disabled ': {
      backgroundColor: '#bfbfbf',
    },
  },
});

const SettingAdminAccount = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useSelector((state) => state.session.user);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidLength, setIsValidLength] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isMatches, setIsMatches] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
    }
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const encryptedPassword = await encrypted(newPassword);
    const response = await fetchApi(`/api/admin/admin_password/${user.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        password: encryptedPassword,
      }),
    });
    if (response.ok) {
      dispatch(helperActions.setPathname(location.pathname));
      setOpenSuccess(true);
    } else {
      throw Error(await response.text());
    }
  };

  return (
    <div>
      <Header noBack noDescription link="/admin/home/list">
        <div style={{ margin: '2px 0', display: 'flex', alignItems: 'center' }}>
          <Typography fontSize={20} fontWeight={700}>{`${t('settingAccountContent')} ${user.id}`}</Typography>
          <Button className="btn-back" onClick={() => navigate('/admin/home/list')}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
          <Button
            disabled={!name ||
                // !/(.+)@(.+)\.(.{2,})/.test(email) ||
                !isValidPassword ||
                !isValidLength ||
                !isMatches}
            className="btn-change"
            onClick={() => handleSubmit()}
          >
            {t('adminChangeSetting')}
          </Button>
        </div>
      </Header>
      <div className={classes.container}>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} my={2}>
              <Typography style={{ marginBottom: '10px' }}>{t('adminSettingsName')}</Typography>
              <TextField
                required
                fullWidth
                name="name"
                autoFocus
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} my={2}>
              <Typography style={{ marginBottom: '10px' }}>{t('adminSettingslogin')}</Typography>
              <TextField
                disabled
                required
                fullWidth
                name="email"
                value={user.email}
                InputProps={{
                  style: {
                    backgroundColor: '#E5F8F2',
                  },
                }}
                // onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} mt={2} mb={1} className={classes.disabledTextField}>
              <Typography fontWeight={700} mb={2}>{t('adminChangePassword')}</Typography>
              <Typography style={{ marginBottom: '10px' }}>{t('adminSettingsCurrent')}</Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  required
                  disabled
                  id="current-password"
                  type={showPassword ? 'text' : 'password'}
                  // value={user.password}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  inputProps={{
                    style: {
                      backgroundColor: '#E5F8F2',
                    },
                  }}
                  style={{ backgroundColor: '#E5F8F2' }}
                />
              </FormControl>
            </Grid>
            <Grid my={1} item xs={12}>
              <Typography style={{ marginBottom: '10px' }}>{t('adminSettingsNew')}</Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  required
                  id="new-password"
                  placeholder={t('passwordField')}
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
            )}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    if (e.target.value.length >= 8) {
                      setIsValidLength(true);
                    } else {
                      setIsValidLength(false);
                    }

                    if (
                      e.target.value.match(
                        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*+=]).{3,}$/,
                      ) ||
                      e.target.value.match(
                        /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*+=]).{3,}$/,
                      ) ||
                      e.target.value.match(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+=]).{3,}$/,
                      ) ||
                      e.target.value.match(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{3,}$/,
                      )
                    ) {
                      setIsValidPassword(true);
                    } else {
                      setIsValidPassword(false);
                    }
                    if (e.target.value === confirmPassword) {
                      setIsMatches(true);
                    } else {
                      setIsMatches(false);
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <div style={{ padding: '16px 0 0 16px' }}>
              <FormControlLabel
                control={(
                  <Radio
                    id="validPassword"
                    checked={isValidPassword}
                    sx={{
                      color: '#02AA9E',
                      '&.Mui-checked': {
                        color: '#02AA9E',
                      },
                    }}
                  />
                  )}
                label={t('passWordValidation')}
              />
              <FormControlLabel
                control={(
                  <Radio
                    id="validLength"
                    checked={isValidLength}
                    sx={{
                      color: '#02AA9E',
                      '&.Mui-checked': {
                        color: '#02AA9E',
                      },
                    }}
                  />
                  )}
                label={t('passWordValidLength')}
              />

            </div>
            <Grid my={1} item xs={12}>
              <Typography style={{ marginBottom: '10px' }}>{t('adminSettingsConfirm')}</Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  required
                  id="outlined-adornment-password"
                  placeholder={t('passwordField')}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
            )}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value === newPassword) {
                      setIsMatches(true);
                    } else {
                      setIsMatches(false);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <ModalSuccess
            openSuccess={openSuccess}
            handleClose={() => {
              setOpenSuccess(false);
              navigate('/admin/tools');
            }}
            title={t('adminSettingCompleted')}
          />
          {/* <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
            <ConfirmationPopup
              btnTitle={t('adminChangeSetting')}
              submit={handleSubmit}
              btnColor="#ff0505"
              confirmText={t('confirmCreateAccount')}
              disabled={
                !name ||
                // !/(.+)@(.+)\.(.{2,})/.test(email) ||
                !isValidPassword ||
                !isValidLength ||
                !isMatches
              }
            />
          </div> */}
        </Box>
      </div>
    </div>
  );
};

export default SettingAdminAccount;
